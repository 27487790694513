import React from 'react';
import { Link } from "gatsby";

// Banner Images
import Grandma from "../../assets/images/banner-image/grandma.png";
import Stars from "../../assets/images/banner-image/stars.png";
import Moon from "../../assets/images/banner-image/moon.png";
import MainPic from "../../assets/images/banner-image/main-pic.png";

// Shape Images
import Shape1 from "../../assets/images/shape1.png";
import Shape2 from "../../assets/images/shape2.svg";
import Shape3 from "../../assets/images/shape3.svg";
import Shape4 from "../../assets/images/shape4.svg";
import Shape5 from "../../assets/images/shape5.png";

const MainBanner = () => {
    return (
		<div className="main-banner">
			<div className="d-table">
				<div className="d-table-cell">
					<div className="container">
						<div className="row h-100 justify-content-center align-items-center">
							<div className="col-lg-5">
								<div className="hero-content">
									<h1>Where magic comes to life</h1>
									<p>StoryToon’s mission is to accelerate children development and early education through technology and innovation.</p>
									
									<Link to="/features" className="btn btn-primary">
										Learn More
									</Link>
								</div>
							</div>

							<div className="col-lg-6 offset-lg-1">
								<div className="banner-image">                        
                                  
                                   <img 
                                        src={Stars} 
                                        className="animate__animated animate__rotateIn animate__delay-0.1s" 
                                        alt="stars"
                                    />
                    
                    				<img 
                                        src={Moon} 
                                        className="animate__animated animate__zoomIn animate__delay-0.1s" 
                                        alt="moon"
                                    />
                
                    				<img 
                                        src={Grandma} 
                                        className="animate__animated animate__bounceIn animate__delay-0.1s" 
                                        alt="Grandma"
                                    />
                        
                                    <img 
                                        src={MainPic} 
                                        className="animate__animated animate__fadeInUp animate__delay-0.1" 
                                        alt="main-pic"
                                    />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

            {/* Shape Images */}
			<div className="shape1">
				<img src={Shape1} alt="shape"/>
			</div>
			<div className="shape2 rotateme">
				<img src={Shape2} alt="shape"/>
			</div>
			<div className="shape3">
				<img src={Shape3} alt="shape"/>
			</div>
			<div className="shape4">
				<img src={Shape4} alt="shape"/>
			</div>
			<div className="shape5">
				<img src={Shape5} alt="shape"/>
			</div>
			<div className="shape6 rotateme">
				<img src={Shape4} alt="shape"/>
			</div>
			<div className="shape7">
				<img src={Shape4} alt="shape"/>
			</div>
			<div className="shape8 rotateme">
				<img src={Shape2} alt="shape"/>
			</div>
		</div>
    )
}

export default MainBanner