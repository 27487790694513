import React from 'react';
import { Link } from "gatsby";
import * as Icon from 'react-feather';

const OurFeatures = () => {
    return (
        <div className="features-area pt-80 pb-50 bg-f7fafd">
            <div className="container">
                <div className="section-title">
                    <h2>Our Features</h2>
                    <div className="bar"></div>
                    <p>StoryToons is working on a wide array of amazing features for our initial release.</p>
                </div>

                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon">
                                <Icon.Settings />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                    Interactive Books
                                </Link>
                            </h3>
                            <p>Let your children fully immerse themselves into our worlds of pure imagination.</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon">
                                <Icon.Mail />
                            </div> 
                            <h3>
                                <Link to="/feature-details">
                                    Real-Time Engagement
                                </Link>
                            </h3>
                            <p>Have you children engage and interact with family members from across the world.</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon bg-c679e3">
                                <Icon.Grid />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                    Real-time Analytics
                                </Link>
                            </h3>
                            <p>Get access to your child's progress through real-time analytics and metrics via dashboards.</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon bg-c679e3">
                                <Icon.Info />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                    Speech-Synced Reading
                                </Link>
                            </h3>
                            <p>As children read aloud, the words will highlight for each successful pronunciation.</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon bg-eb6b3d">
                                <Icon.Box />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                    Narrators
                                </Link>
                            </h3>
                            <p>Sit back and relax as delightful narrators guide you through our stories.</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon bg-eb6b3d">
                                <Icon.Bell />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                    Gamification
                                </Link>
                            </h3>
                            <p>We want your child to always continue learning and there is no better way than awarding them on their path.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurFeatures;  