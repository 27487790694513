import React from 'react';
import * as Icon from 'react-feather';

// Service Images
import Box from "../../assets/images/services-right-image/box.png";
import SpaceMoon from "../../assets/images/services-right-image/space_moon.png";
import CercleShape from "../../assets/images/services-right-image/cercle-shape.png";
import ServiceRightMainPic from "../../assets/images/services-right-image/service-right-main-pic.png"; 

// Features
import Spaceship from "../../assets/images/services-left-image/spaceship.png";
import ServiceLeftMainPic from "../../assets/images/services-left-image/service-left-main-pic.png";

const ServicesArea = () => {
    return (
        <>
            <div className="services-area ptb-80 bg-f7fafd">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 services-content">
                            <div className="section-title">
                                <h2>Learn. Play. Discover.</h2>
                                <div className="bar"></div>
                                <p>StoryToons provides an incredible and interactive experience for your child's early development.</p>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Activity /> Personalized
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Lock /> Safe
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Check /> Proven
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Smile /> Joyful
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Sun /> Playful
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Zap /> Engaging
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Moon /> Fun
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Star /> Immersive
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 services-right-image">
                            <img 
                                src={SpaceMoon}
                                className="animate__animated animate__fadeInDown animate__delay-0.2s" 
                                alt="spacemoon"
                            />
            
                            <img 
                                src={CercleShape}
                                className="bg-image rotateme" 
                                alt="shape"
                            />
                         
                            <img 
                                src={SpaceMoon}
                                className="animate__animated animate__fadeInUp animate__delay-0.2s" 
                                alt="spacemoon"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="services-area ptb-80">
                <div className="container">
                    <div className="row h-100 justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 services-left-image">
                            <img 
                                src={Spaceship}
                                className="animate__animated animate__fadeInLeft animate__delay-0.2s" 
                                alt="spaceshop"
                            />
                    
                            <img 
                                src={CercleShape}
                                className="bg-image rotateme" 
                                alt="shape"
                            />
           
                            <img 
                                src={ServiceLeftMainPic}
                                className="animate__animated animate__fadeInUp animate__delay-0.2s" 
                                alt="main-pic"
                            />
                        </div>

                        <div className="col-lg-6 col-md-12 services-content">
                            <div className="section-title">
                                <h2>Stories</h2>
                                <div className="bar"></div>
                                <p>StoryToons provides a wide spectrum of different genres to fully engage children into immersive worlds.</p>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Moon /> Fantasy
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Sun /> Fairy Tales & Folklore
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Smile /> Classic
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Feather /> Mystery
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.CloudLightning /> Historical
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.BookOpen /> Science Fiction
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Award /> Legend
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Anchor /> Fable
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServicesArea;