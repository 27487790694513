import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/Navbar";
import MainBanner from "../components/Main/MainBanner";
import Features from "../components/Main/Features";
import ServicesArea from "../components/Main/ServicesArea";
import OurFeatures from "../components/Main/OurFeatures";
import Footer from "../components/_App/Footer";

const IndexPage = () => (
    <Layout>
        <SEO title="Home" />
        <Navbar />
        <MainBanner />
        <Features />
        <ServicesArea />
        <OurFeatures />
        <Footer />
    </Layout>
)

export default IndexPage;
