import React from 'react';
import * as Icon from 'react-feather';
import { Link } from "gatsby";

const Features = () => {
    return (
        <div className="boxes-area">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-3 col-sm-6">
						<div className="single-box">
							<div className="icon">
								<Icon.Users />
							</div>

							<h3>
								Social Learning
							</h3>

							<p>Amazing tools for navigating social skills, empathy, and confidence.</p>
						</div>
					</div>

					<div className="col-lg-3 col-sm-6">
						<div className="single-box bg-f78acb">
							<div className="icon">
                                <Icon.Book />
							</div>

							<h3>
								Reading
							</h3>

							<p>A step-by-step pathway that leads to literacy and reading comprehension.</p>
						</div>
					</div>

					<div className="col-lg-3 col-sm-6">
						<div className="single-box bg-c679e3">
							<div className="icon">
								<Icon.Activity />
							</div>

							<h3>
								Thinking Skills
							</h3>

							<p>Engaging brain games for big thinking and adapting critical thinking skills.</p>
						</div>
					</div>

					<div className="col-lg-3 col-sm-6">
						<div className="single-box bg-eb6b3d">
							<div className="icon">
                                <Icon.CloudLightning />
							</div>

							<h3>
								Creativity
							</h3>

							<p>An environment for imaginations to fully run free and be unstoppable.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
    )
}

export default Features;
